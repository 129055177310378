const cards = new Map([
    [
        "total",
        {
            id: 1,
            title: "Общий ЭИ",
            max: null,
            min: null,
            descriptionContent: "Эмоциональный интеллект - это четко определяемая и измеряемая когнитивная способность перерабатывать информацию, содержащуюся в эмоциях, определять значение эмоций, их связи друг с другом, использовать эмоциональную информацию в качестве основы для мышления и принятия решений.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["general"],
            structure: false
        },
    ],
    [
        "domain_1",
        {
            id: 1,
            title: "Опытный ЭИ",
            max: null,
            min: null,
            descriptionContent: "Опытный эмоциональный интеллект - это способность воспринимать эмоциональную информацию, отвечать на нее и распоряжаться ею, не обязательно при этом понимая ее. Опытный ЭИ показывает выраженность способности воспринимать эмоциональный опыт, распознавать его, сравнивать с другими чувствами и ощущениями и понимать, как этот опыт взаимодействует с процессами мышления.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["experienced"],
        },
    ],
    [
        "domain_2",
        {
            id: 2,
            title: "Стратегический ЭИ",
            max: null,
            min: null,
            descriptionContent: "Стратегический эмоциональный интеллект - это способность понимать эмоциональную информацию и использовать ее стратегически – для планирования и саморегуляции. Стратегический ЭИ показывает, насколько хорошо человек может понимать эмоции и управлять ими, не обязательно при этом хорошо воспринимая чувства или полностью испытывая их, а также в какой мере человек способен управлять своими и чужими эмоциями.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["strategic"],
        },
    ],
    [
        "branch_1",
        {
            id: 3,
            title: "Распознавание эмоций",
            max: null,
            min: null,
            descriptionContent: "Распознавание эмоций – это способность распознавать свои эмоции и эмоции окружающих через речь, мимику, внешний вид, физические проявления эмоций, определять эмоции через произведения искусства, способность соотносить свои телесные ощущения с переживаемыми эмоциями, дифференцировать истинные и ложные выражения эмоций и чувств.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["recognition"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "branch_2",
        {
            id: 4,
            title: "Использование эмоций для решения задач",
            max: null,
            min: null,
            descriptionContent: "Использование эмоций для решения задач – это способность грамотно использовать определенные эмоции в качестве основы для принятия решений, для повышения эффективности мышления и деятельности, для решения конкретных задач.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["using"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "branch_3",
        {
            id: 5,
            title: "Анализ эмоций",
            max: null,
            min: null,
            descriptionContent: "Анализ эмоций – это способность понимать и анализировать сложные эмоции и чувства, распознавать причины эмоций, а также прогнозировать последствия эмоций и эмоциональные сценарии, как эмоции переходят от одной к другой.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["understanding"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "branch_4",
        {
            id: 6,
            title: "Управление эмоциями",
            max: null,
            min: null,
            descriptionContent: "Управление эмоциями – это способность регулировать свои эмоции и влиять на эмоции других людей, создавать эффективные стратегии, которые помогают вам в достижении целей, способность «заряжать» своими эмоциями окружающих, вовлекая их в свои идеи.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["management"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_1",
        {
            id: 7,
            title: "Распознавание эмоций в лицах",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на эмоциональное оценивание лиц.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["faces"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_2",
        {
            id: 8,
            title: "Применение эмоций в действиях и мышлении",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности к определению эмоций, эффективных для содействия мышлению в конкретной деятельности.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["facilitation"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_3",
        {
            id: 9,
            title: "Понимание смены эмоций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека понимать особенности перехода эмоций друг в друга, механизмы, провоцирующие их изменения, а также специфику протекания эмоций с течением времени.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["changes"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_4",
        {
            id: 10,
            title: "Регуляция своих эмоций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на изучение способности человека регулировать собственные эмоциональные состояния.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["managingYour"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_5",
        {
            id: 11,
            title: "Изображения",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека понимать особенности перехода эмоций друг в друга, механизмы, провоцирующие их изменения, а также специфику протекания эмоций с течением времени.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["images"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_6",
        {
            id: 12,
            title: "Эмпатия",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека описывать свои эмоциональные состояния и почувствовать эмоции другого.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["empathy"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_7",
        {
            id: 13,
            title: "Понимание соединения эмоций в чувства",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на диагностику способности человека различать эмоции, входящие в комплексные чувства, а также понимать взаимодействие эмоций между собой.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["mixing"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_8",
        {
            id: 14,
            title: "Влияние на эмоции других",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности человека регулировать эмоциональные состояния других людей.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["influencing"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_9a",
        {
            id: 15,
            title: "Распознавание эмоций в контексте ситуаций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на распознавание эмоций на лицах в динамике и с учетом дополнительных факторов.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["situations"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
    [
        "section_9b",
        {
            id: 16,
            title: "Понимание изменения эмоций в контексте ситуаций",
            max: null,
            min: null,
            descriptionContent: "Раздел, направленный на измерение способности оценивать динамику эмоциональных состояний с учетом внешних факторов и объективного хода событий.",
            descriptionFooter: "Aliquip reprehenderit anim ut minim reprehenderit in enim qui laboris aute consectetur eiusmod eiusmod aute.",
            structureClass: ["dynamics"],
            detailingClass: ["parameter-card-detailing"],
        },
    ],
]);

export default cards;
